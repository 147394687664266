<template>
  <div class="clear">
    <div class="footer">
      <div class="footer_part_one">
        <div class="header_left PangMenZhengDao">
          <img src="../../../hulian_frontweb/public/imgs/logo.png" />
          复变时空互联科技
        </div>
        <div class="other">
          <div class="adrres">
            <img class="img" src="../../../hulian_frontweb/public/imgs/address.png" alt="">
            <span>武汉理工大学产业大楼8楼</span>
          </div>
          <div class="phone">
            <img class="img" src="../../../hulian_frontweb/public/imgs/iphone.png" alt="">
            <span>13307122673</span>
          </div>
          <div class="email">
            <img class="img" src="../../../hulian_frontweb/public/imgs/email.png" alt="">
            <span>hupeng@cddiot.com</span>
          </div>
        </div>
      </div>
      <div class="footer_part_two">
        <text class="title">产品中心</text>
        <div class="other">
          <text @click="$router.push('/mes')">MES生产制造系统</text>
          <text @click="$router.push('/wms')">WMS仓储管理系统</text>
          <text @click="$router.push('/building')">智慧工地一体化管理平台</text>
        </div>
      </div>
      <div class="footer_part_two">
        <text class="title">服务与支持</text>
        <div class="other">
          <text @click="$router.push('/technology')">服务体系</text>
        </div>
      </div>
      <div class="footer_part_two">
        <text class="title">企业动态</text>
        <div class="other">
          <text @click="$router.push('/dynamics')">企业动态</text>
          <text @click="$router.push('/news')">行业新闻</text>
        </div>
      </div>
      <div class="footer_part_two">
        <text class="title">关于我们</text>
        <div class="other">
          <text @click="$router.push('/introduction')">企业介绍</text>
        </div>
      </div>
    </div>
    <div class="footerFilings">
      <text>Copyright ©2019-2024 复变时空（武汉）数据科技有限公司 版权所有 保留一切权利</text>
      <br>
      <text>隐私政策 | 法律声明 | </text> <a target="_blank" href="https://beian.miit.gov.cn">鄂ICP备20001581号-1</a> <text>      中国</text>
    </div>
  </div>

</template>
<script>

  export default {
    components: {

    },
    data() {
      return {

      }
    },
    created() {


    },
    methods: {

    }
  }
</script>
<style  lang="less" scoped>
.clear {
  margin: 0;
  padding: 0;
  .footer {
    width: 100%;
    height: 18.428571rem;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    padding: 0 35.142857rem 0 7.142857rem;
    .footer_part_one {
      margin: 1.571429rem 25.714286rem 0 0;
      .header_left{
        font-size: 2.5714285714286rem;
        display: flex;
        color: #5BA3D8;
        img{
          width: 2.5714285714286rem;
          height: 2.5714285714286rem;
          margin-right: 1rem;
        }
      }
      .other {
        margin: 2.928571rem 0 0 2.928571rem;
        line-height: 3.142857rem;
        text-align: left;
        .img {
          width: 1.728571rem;
          height: 1.728571rem;
          margin: 0 .714286rem 0 0;
          vertical-align: middle;
        }
      }
    }
    .footer_part_two {
      text-align: left;
      margin-top: 2rem;
      .title {
        font-size: 1.285714rem;
        font-weight: bold;
      }
      .other {
        margin-top: 1.357143rem;
        display: flex;
        flex-direction: column;
        text {
          font-size: 1.142857rem;
          line-height: 2.314286rem;
          transition: all 0.5s;
          cursor: pointer;
        }
        text:hover {
          color: #DFDEDE;
        }
      }
    }
  }
  .footerFilings {
    width: 100%;
    height: 4.285714rem;
    border-top: 1px solid #DFDEDE;
    background-color: #fff;
    text-align: center;
    line-height: 4.285714rem;
    color: #444444;
    font-size: 1.142857rem;
  }
}
</style>
