<template>
  <a-config-provider :locale="locale">
    <router-view></router-view>
  </a-config-provider>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import zhCN from "ant-design-vue/es/locale/zh_CN";
export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      // locale: zhCN,
    };
  },
};
</script>

<style>
.PangMenZhengDao{   font-family: PangMenZhengDao;}
@font-face {
  font-family: "PangMenZhengDao";
  src: url(~@/assets/PangMenZhengDao.ttf) format("truetype");
  }
  body{
    
  font-size: 1.285714285714286rem;
  }
.flex{
  display: flex;
}
.flex-one{
  flex:1
}
.flex-end{
  justify-content: flex-end;
}
.label{
  background: #0E3871;
  height: 2.2857143rem;
  display: inline-block;
  padding: 0 0.57143rem;
  min-width: 4.286rem;
  line-height:2.2857143rem;
  margin:0 0.57143rem;
  color:#FFA113;
}
*{
  padding:0;margin: 0;
  box-sizing: border-box;
} 
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100%;
}
.ant-picker-panels td,
.ant-picker-content thead th,
.ant-picker-header-view
{
  color: #fff;
}
.ant-picker-header,
.ant-picker-panel
 {
  border-bottom-color: #0096D8 !important;
}
.ant-picker-range-wrapper.ant-picker-date-range-wrapper {
  border: 1px solid #0096D8;
}
.ant-picker-date-panel {
  background-color: #11214C;
}
.ant-select-dropdown.ant-select-dropdown-placement-bottomLeft {
  border: 1px solid #0096D8;
  background-color: #11214C;
}
.ant-select-item-option-active,
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #0096D8 !important;
}
.ant-select-item-option-content {
  color: #fff;
}
.ant-picker-cell-disabled {
  color: #00000040 !important;
}
.ant-picker-cell-disabled::before {
  background-color: #fff !important;
}
.ant-picker-cell-in-range .ant-picker-cell-inner {
  color: #11214c
}


</style>
