import Layout from '@/components/Layout'

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [ 
  // 预约体验
  {
    path: '/detailNews',
    name: 'detailNews',
    component: () => import('@/views/dynamics/detailNews'),
    hidden: true
  },  
  // 预约体验
  {
    path: '/experience',
    component: () => import('@/views/experience/Index'),
    hidden: true
  },
  // 预约成功
  {
    path: '/success',
    component: () => import('@/views/experience/success'),
    hidden: true
  },
  {
    path: '/',
    name: 'index',
    component: Layout, 
    redirect:"/index",
    children: [
      {
        path: '/index',
        component: () => import('@/views/home/Index'),
        hidden: true
      },
      {
        path: '/product',
        redirect:"/mes",
        hidden: true,
        children:[
          {
            path: '/mes',
            component: () => import('@/views/product/mes'),
            hidden: true
          },          
          {
            path: '/wms',
            component: () => import('@/views/product/wms'),
            hidden: true
          },

          {
            path: '/building',
            component: () => import('@/views/product/building'),
            hidden: true
          },
        ]
      },
      {
        path: '/dynamics',
        redirect:"/dynamics",        
        hidden: true,
        children:[
          {
            path: '/dynamics',
            component: () => import('@/views/dynamics/Index'),
            hidden: true
          },
          {
            path: '/news',
            component: () => import('@/views/dynamics/news'),
            hidden: true
          },          
        ]        
      },
      {
        path: '/aboutus',
        redirect:"/responsibility", 
        hidden: true,
        children:[
          {
            path: '/introduction',
            component: () => import('@/views/aboutus/introduction'),
            hidden: true
          },
          {
            path: '/contact',
            component: () => import('@/views/aboutus/contact'),
            hidden: true
          },
          {
            path: '/join',
            component: () => import('@/views/aboutus/join'),
            hidden: true
          },
        ]
      },
      {
        path: '/technology',
        component: () => import('@/views/technology/Index'),
        hidden: true
      },

    ]
  }
]
