// // import { createApp } from 'vue'
// import { createRouter,createWebHistory} from 'vue-router'

// // let app = createApp()
// // app.use(Router)

// const router  = createRouter({
//     mode: createWebHistory(),
//     routes: constantRouterMap
//   })
// export default router

import { createRouter,createWebHashHistory } from 'vue-router'
import { constantRouterMap } from '@/config/router.config'
const router = createRouter({
  history: createWebHashHistory(), // createWebHashHistory 为哈希模式的路由，如果需要选择 histiry 模式，可以用 createWebHistory 方法。
  routes: constantRouterMap
})
 
 
export default router