<template>
 <div class="layout">
  <!-- <div class="layout-header" >
    <layout-header></layout-header>
  </div> -->
  <div class="layout-content">
    <router-view />
  </div>
  <div class="layout-footer">
    <layout-footer></layout-footer>
  </div>
 </div>
    
</template>
<script>
import LayoutFooter from './LayoutFooter.vue';
// import LayoutHeader from './LayoutHeader.vue';
  export default {
    components: {
      // LayoutHeader,
      LayoutFooter
    },
   
  };
</script>
<style scoped>
.layout{
  display: flex;
  width: 100%;
  min-width: 1000px;
  height: 100%;
  flex-direction: column;   
}
.layout-header{
  position: fixed;
  left: 0;
  top:0;
  width: 100%;
  z-index: 99;
}
.layout-content{
  flex: 1;
  /* display: flex; */
  flex-direction: row;
}
.layout-content .layout-menu{
  overflow: auto;
  width: 17.57rem;
}
.layout-content .layout-view{
   flex: 1;
  padding: 0 1.42rem 1.42rem 0 ;
  overflow: auto;
}
</style>